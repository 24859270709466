#header {
    background-image: url('../img/banner-7.jpg');
    background-size: cover;
}

#header .container {
    color: #fff;
}

#header small {
    font-size: 10px;
}